import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (
    <div>
        <h4>Best place to find your next React Web Developer</h4>
        <br />
        <ul>
            <li><a href='https://www.SoftwareGladiator.com/' target="_blank"><img src='CodeOfArmsLogo.png' height='20' />&nbsp;Software Gladiator - IT Contractor Services and Software Solutions</a></li>
        </ul>
    </div>
);

export default connect()(Home);
